import { getQueryParam } from "./url";
import { InstanceData } from "../models/shared/InstanceData";
import { convertData } from "./shared/firebase";
import { Shortlink } from "../models/shared/Shortlink";

const API_URL = process.env.REACT_APP_USE_EMULATORS
    ? 'http://localhost:5001/wix-shortlink/europe-west1'
    : 'https://europe-west1-wix-shortlink.cloudfunctions.net';


export const getInstanceData = async (): Promise<Partial<InstanceData>> => {
    const instance = getQueryParam('instance');
    const endpoint = `${API_URL}/getInstanceData?instance=${instance}`;

    const result = await fetch(endpoint);

    return convertData(await result.json());
}

interface CreateShortlinkPayload {
    targetUrl: string
    name: string
}
export const createShortlink = async (payload: CreateShortlinkPayload): Promise<void> => {
    const instance = getQueryParam('instance');
    const endpoint = `${API_URL}/createShortlink?instance=${instance}`;

    const result = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!result.ok) {
        throw new Error(await result.text());
    }
}
export const deleteShortlink = async (shortlinkId: string): Promise<void> => {
    const instance = getQueryParam('instance');
    const endpoint = `${API_URL}/deleteShortlink?instance=${instance}&id=${shortlinkId}`;

    const result = await fetch(endpoint, {
        method: 'DELETE',
    });

    if (!result.ok) {
        throw new Error(await result.text());
    }
}
export const updateShortlink = async (shortlinkId: string, update: Partial<Shortlink>): Promise<void> => {
    const instance = getQueryParam('instance');
    const endpoint = `${API_URL}/updateShortlink?instance=${instance}&id=${shortlinkId}`;

    const result = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(update),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!result.ok) {
        throw new Error(await result.text());
    }
}
