import React, { useMemo } from 'react'
import { StatsRange, StatsSummary } from '../models/Stats'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { getStatDayKey } from '../utils/shared/format'
import { PRIMARY_COLOR } from '../utils/shared/constants'

interface Props {
    statsSummary: StatsSummary,
    range: StatsRange,
}

function TimeStatsChart(props: Props) {
    const { statsSummary, range } = props

    const data = useMemo(() => {
        const xAxis = generateAllDates(range.start, range.end)

        return xAxis.map(date => {
            return {
                name: date,
                count: statsSummary.perDay[date] || 0,
            }
        });
    }, [statsSummary, range])

    return <ResponsiveContainer width="100%" height={250} className='mt-5'>
        <BarChart width={730} height={250} data={data}>
            <Tooltip />
            <XAxis dataKey="name" />
            <YAxis />
            <Bar dataKey="count" fill={PRIMARY_COLOR} />
        </BarChart>
    </ResponsiveContainer >
}

export default TimeStatsChart

function generateAllDates(start: Date, end: Date) {
    const dates = []
    let currentDate = new Date(start)
    while (currentDate <= end) {
        dates.push(getStatDayKey(currentDate))
        currentDate.setDate(currentDate.getDate() + 1)
    }
    return dates;
}

