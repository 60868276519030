import React, { useEffect, useState } from 'react'
import texts from '../utils/shared/texts'
import TrialInfo from '../components/TrialInfo';
import NewShortlink from '../components/NewShortlink';
import ShortlinkList from '../components/ShortlinkList';
import { useInstanceContext } from '../context/InstanceContext';
import { query, collection, where, onSnapshot } from 'firebase/firestore';
import { Shortlink, ShortlinkStatus } from '../models/shared/Shortlink';
import { fromFirebaseDocs } from '../utils/shared/firebase';
import { Collections, REVIEW_LINK, UPGRADE_LINK } from '../utils/shared/constants';
import { useFirestore } from 'reactfire';
import { Changes } from '../models/FirestoreChanges';
import { MAILTO } from '../utils/shared/constants';
import StatisticsPage from './StatisticsPage';
import { Box, Button, Card, IconButton, Loader, Page, Tooltip } from '@wix/design-system';
import { Add, Favorite, HelpCircle, Reviews } from '@wix/wix-ui-icons-common';

export default function Dashboard() {
    const [showNew, setShowNew] = useState(false)
    const { onTrial, trialEnded, maxLinks, instanceData, loaded } = useInstanceContext();
    const [shortlinks, setShortlinks] = React.useState<Shortlink[]>([])
    const [changes, setChanges] = React.useState<Changes>({})
    const db = useFirestore();
    const [showStatsFor, setShowStatsFor] = useState<string>('')

    const instanceId = (window as any).Wix.Utils.getInstanceId();
    useEffect(() => {
        const q = query(collection(db, Collections.shortlinks), where('instanceId', '==', instanceId), where('status', '==', ShortlinkStatus.ACTIVE));
        return onSnapshot(q, (querySnapshot) => {
            const newShortlinks = fromFirebaseDocs<Shortlink>(querySnapshot.docs);
            const firestoreChanges = querySnapshot.docChanges();

            const newChanges: Changes = {};
            for (const change of firestoreChanges) {
                newChanges[change.doc.id] = change.type;
            }

            setChanges(newChanges);
            setShortlinks(newShortlinks);
        });
    }, [db, instanceId])

    if (showStatsFor) {
        return <StatisticsPage
            shortlinks={shortlinks}
            defaultShortlinkId={showStatsFor}
            onBack={() => setShowStatsFor('')}
        />
    }



    const upgradeTriggered = () => {
        const instanceId = instanceData.instanceId;
        window.location.href = UPGRADE_LINK + instanceId;
    }

    const maxReached = !!maxLinks && shortlinks.length >= maxLinks;
    const trialExpired = onTrial && trialEnded;
    const subtitle = "Gain insights and manage your links inside WIX";

    if (!loaded) {
        return <Page>
            <Page.Header title={texts.appName} subtitle={subtitle} />

            <Page.Content>

                <Card>

                    <Card.Content >
                        <div className="center">
                            <Loader text="Loading" />
                        </div>
                    </Card.Content>
                </Card>
            </Page.Content>
        </Page>
    }


    return (
        <>
            <span data-instance-id={instanceData.instanceId} />
            <Page>
                <Page.Header title={texts.appName} subtitle={subtitle}
                    actionsBar={
                        <Box gap={"12px"}>
                            {trialExpired || maxReached
                                ? <Tooltip content={
                                    trialExpired ? 'Your trial has expired. Upgrade to reactivate links' : 'You have reached the limit of your current plan. Upgrade to add more links.'
                                }>
                                    <Button skin="premium" onClick={upgradeTriggered}
                                        prefixIcon={<Favorite />}
                                    >
                                        {trialEnded ? 'Start trial' : 'Upgrade'}
                                    </Button>
                                </Tooltip>
                                : <Button skin="standard" onClick={() => setShowNew(!showNew)} prefixIcon={<Add />}>
                                    New QR Code
                                </Button>
                            }
                            <IconButton priority="secondary" onClick={() => window.location.href = MAILTO}>
                                <HelpCircle />
                            </IconButton>
                        </Box>
                    }
                />

                <Page.Content>
                    <Box gap={'24px'} direction='vertical'>



                        <NewShortlink show={showNew} onCreated={() => setShowNew(false)} />

                        {!trialExpired && <Card className='pb-3'>
                            <Card.Header title='Your QR Codes' />


                            <ShortlinkList
                                onShowStats={setShowStatsFor}
                                showShortLinkForm={() => setShowNew(true)}
                                shortlinks={shortlinks}
                                changes={changes}
                            />
                        </Card>}

                        {shortlinks.length > 0 && !trialExpired && <Card>
                            <Card.Header title='App Review'
                                subtitle='Help us grow by leaving a review on Wix App Market'
                            />
                            <Card.Content>
                                <a href={REVIEW_LINK} target='_blank' rel='noreferrer'>
                                    <Button prefixIcon={<Reviews />} skin='inverted' >Leave a Review</Button>
                                </a>

                            </Card.Content>
                        </Card>}

                        <TrialInfo />
                    </Box>

                </Page.Content >
            </Page >
        </>
    )
}
