
export const getQueryParam = (name: 'instance' | 'compId' | 'viewMode' | 'origCompId') => {
    const queryParamValue = new URLSearchParams(window.location.search).get(name);
    if (!queryParamValue) {
        throw new Error(`client:No ${name} query param`);
    }
    return queryParamValue;
}


