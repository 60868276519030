import React, { createContext, useEffect } from "react";
import { InstanceData } from "../models/shared/InstanceData";
import { getRemainingTrialDays } from '../utils/logic';
import { getInstanceData } from "../utils/api";
import { PLANS } from "../utils/shared/constants";

const MAX_LINKS_BASE = 5;
const MAX_LINKS_BUSINESS = 25;
const DEFAULT_DOMAIN = 'wix-l.in';

interface InstanceContext {
    instanceData: Partial<InstanceData>
    onTrial: boolean,
    daysLeft: number,
    trialEnded: boolean,
    loaded: boolean,
    maxLinks: number | null
    domain: string,
}

const instanceContext = createContext<InstanceContext>({
    instanceData: {},
    onTrial: false,
    daysLeft: 0,
    trialEnded: false,
    loaded: false,
    maxLinks: 5,
    domain: DEFAULT_DOMAIN,
})

export const useInstanceContext = () => React.useContext(instanceContext);

interface Props {
    children: React.ReactNode
}

export const InstaceContextProvider = (props: Props) => {
    const [instanceData, setInstanceData] = React.useState<Partial<InstanceData>>({});
    const [loaded, setLoaded] = React.useState(false);
    const onTrial = !instanceData.vendorProductId;
    const daysLeft = getRemainingTrialDays(instanceData.trialEndsAt || new Date());
    const trialEnded = daysLeft < 1;

    const planType = instanceData.vendorProductId || instanceData.trialVendorProductId || PLANS.base;
    let maxLinks: number | null = MAX_LINKS_BASE;
    if (planType === PLANS.business) {
        maxLinks = MAX_LINKS_BUSINESS;
    } else if (planType === PLANS.enterprise) {
        maxLinks = null;
    }
    const domain = instanceData.customDomain || DEFAULT_DOMAIN;

    useEffect(() => {
        getInstanceData().then(data => {
            setInstanceData(data)
            setLoaded(true)
        });
    }, [])

    return <instanceContext.Provider value={
        {
            instanceData,
            onTrial,
            daysLeft,
            trialEnded,
            loaded,
            maxLinks,
            domain
        }
    }>{props.children}</instanceContext.Provider>
}
