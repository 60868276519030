import React from 'react';
import { FirebaseAppProvider, FirestoreProvider, useFirebaseApp } from 'reactfire';
import { firebaseConfig } from './utils/firebaseConfig';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import './App.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Dashboard from './pages/Dashboard';
import { useEmulators } from './utils/firebase';
import { InstaceContextProvider } from './context/InstanceContext';
import NotFound from './pages/NotFound';
import '@wix/design-system/styles.global.css';

function ContentApp() {

  if (window.location.pathname === '/dashboard') {
    return <InstaceContextProvider><Dashboard /></InstaceContextProvider>
  }

  return <NotFound />
}

function FirebaseApp() {
  const firebaseApp = useFirebaseApp();
  const firestoreInstance = getFirestore(firebaseApp);
  if (useEmulators) {
    connectFirestoreEmulator(firestoreInstance, "localhost", 8080);
  }

  return <FirestoreProvider sdk={firestoreInstance}>
    <ContentApp />
  </FirestoreProvider>
}

function App() {
  return <FirebaseAppProvider firebaseConfig={firebaseConfig}>

    <FirebaseApp />

  </FirebaseAppProvider>
}

export default App;