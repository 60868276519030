import React, { useEffect, useMemo, useState } from 'react'
import { Shortlink } from '../models/shared/Shortlink'
import CountUpNumber from '../components/CountUpNumber'
import { useFirestore } from 'reactfire';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { Collections } from '../utils/shared/constants';
import { getStatDayKey } from '../utils/shared/format';
import { fromFirebaseDocs } from '../utils/shared/firebase';
import { DayStats } from '../models/shared/DayStats';
import TimeStatsChart from '../components/TimeStatsChart';
import { RangeOption, RangeType, StatsRange, StatsSummary } from '../models/Stats';
import PerCategoryChart from '../components/BrowserChart';
import { Button, Card, Dropdown, MultiSelectCheckbox, Page } from '@wix/design-system';
import { ArrowLeft } from '@wix/wix-ui-icons-common';


const rangeOptions: RangeOption[] = [
    { label: 'Last 7 days', value: 'last7Days', days: 7 },
    { label: 'Last 30 days', value: 'last30Days', days: 30 },
    { label: 'Last 90 days', value: 'last90Days', days: 90 }
]

const emptyStats: StatsSummary = { total: 0, perDay: {}, perOS: {}, perBrowser: {} }

interface Props {
    shortlinks: Shortlink[],
    defaultShortlinkId: string,
    onBack: () => void
}

function StatisticsPage(props: Props) {
    const { shortlinks } = props
    const [range, setRange] = React.useState<RangeType>('last30Days')
    const currentRange = rangeOptions.find(r => r.value === range) || rangeOptions[0];
    const db = useFirestore();
    const [daySummaries, setDaySummaries] = React.useState<Record<string, DayStats[]>>({});

    const [selectedLinkIds, setSelectedLinkIds] = useState<string[]>([props.defaultShortlinkId]);

    const interval: StatsRange = useMemo(() => {
        const now = new Date();
        const start = new Date(now);
        start.setDate(now.getDate() - currentRange.days);
        return { start, end: now, startKey: getStatDayKey(start), endKey: getStatDayKey(now) }
    }, [currentRange])


    useEffect(() => {
        if (!interval || !interval.start || !interval.end) {
            return;
        }

        const unsubscribeFunctions: Function[] = [];

        for (const shortlinkId of selectedLinkIds) {
            const q = query(collection(db, Collections.shortlinks, shortlinkId, Collections.stats),
                where('id', '>=', interval.startKey),
                where('id', '<=', interval.endKey));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const newDayStats = fromFirebaseDocs<DayStats>(querySnapshot.docs);
                setDaySummaries(prev => ({ ...prev, [shortlinkId]: newDayStats }))
            });
            unsubscribeFunctions.push(unsubscribe);
        }

        return () => {
            for (const unsubscribe of unsubscribeFunctions) {
                unsubscribe();
            }
        }
    }, [db, interval, selectedLinkIds])

    useEffect(() => {
        const dayStatsKeys = Object.keys(daySummaries);
        const extraKeys = dayStatsKeys.filter(key => !selectedLinkIds.includes(key));
        if (!extraKeys.length) {
            return;
        }

        const newDaySummaries: Record<string, DayStats[]> = {};
        for (const key of selectedLinkIds) {
            newDaySummaries[key] = daySummaries[key];
        }
        setDaySummaries(newDaySummaries);
    }, [selectedLinkIds, daySummaries])

    const statsSummary = useMemo(() => {
        const allDayStats = Object.values(daySummaries).flat();

        const newStats: StatsSummary = JSON.parse(JSON.stringify(emptyStats));
        for (const dayStats of allDayStats) {
            newStats.total += dayStats.visited;
            newStats.perDay[dayStats.id] = (newStats.perDay[dayStats.id] || 0) + dayStats.visited;
            for (const os in dayStats.perOS) {
                newStats.perOS[os] = (newStats.perOS[os] || 0) + dayStats.perOS[os];
            }
            for (const browser in dayStats.perBrowser) {
                newStats.perBrowser[browser] = (newStats.perBrowser[browser] || 0) + dayStats.perBrowser[browser];
            }
        }
        return newStats;
    }, [daySummaries]);

    const selectedShortlinks = shortlinks.filter(link => selectedLinkIds.includes(link.id));
    const totalVisited = selectedShortlinks.reduce((acc, link) => acc + link.visited, 0);

    let title = 'Statistics';
    if (selectedLinkIds.length === 1) {
        title += ` - ${selectedShortlinks[0].name}`;
    } else if (selectedShortlinks.length > 1) {
        title += ` - ${selectedShortlinks.length} links`;
    }
    return <Page>
        <Page.Header title={title}
            subtitle="Explore detailed statistics for links"
            actionsBar={
                <Button
                    prefixIcon={<ArrowLeft />}
                    onClick={props.onBack}>
                    Back
                </Button>
            }
        />

        <Page.Content>
            <Card>
                <Card.Content>


                    <div className='d-flex justify-content-between mb-4'>
                        <MultiSelectCheckbox
                            placeholder='Select link'
                            selectedOptions={selectedLinkIds}
                            options={
                                shortlinks.map(link => ({
                                    value: link.name,
                                    id: link.id,
                                }))

                            }
                            onSelect={(id: any) => setSelectedLinkIds([...selectedLinkIds, id])}
                            onDeselect={(id: any) => setSelectedLinkIds(selectedLinkIds.filter(item => item !== id))}
                        />

                        <Dropdown
                            selectedId={range}
                            onSelect={(option) => setRange(option.id as RangeType)}
                            value={range}
                            options={rangeOptions.map(option => ({ id: option.value, value: option.label }))}
                        />

                    </div>

                    <div className='d-flex justify-content-around'>
                        <CountUpNumber label='All time visits' valueNumber={totalVisited} />
                        <CountUpNumber label={currentRange.label} valueNumber={statsSummary.total} />
                    </div>

                    <TimeStatsChart range={interval} statsSummary={statsSummary} />

                    <div className='d-flex justify-content-around my-5'>
                        <PerCategoryChart statsSummary={statsSummary} category='perBrowser' title='Count per Browser' />
                        <PerCategoryChart statsSummary={statsSummary} category='perOS' title='Count per Device' />
                    </div>
                </Card.Content>
            </Card>
        </Page.Content>
    </Page>
}

export default StatisticsPage
