import { useMemo } from 'react'
import { StatsSummary } from '../models/Stats'
import { Cell, Pie, PieChart, Tooltip } from 'recharts'
import { COLORS_SHADES } from '../utils/shared/constants'

interface Props {
    statsSummary: StatsSummary,
    category: 'perOS' | 'perBrowser'
    title: string
}


const CustomTooltip = (props: any) => {
    const { payload } = props;
    if (!payload || !payload.length) {
        return null;
    }

    const data = payload[0].payload;
    const borderProps = `2px solid ${data.fill}`;
    return (
        <div className="custom-tooltip" style={{ border: borderProps }}>
            <p className="desc m-0">{data.name}: {data.value} ({data.percentage}%)</p>
        </div>
    );
};

function PerCategoryChart(props: Props) {
    const { statsSummary } = props

    const data = useMemo(() => {
        const newData = Object.keys(statsSummary[props.category]).map(browser => {
            const percentage = ((statsSummary[props.category][browser] / statsSummary.total) * 100).toFixed(2);
            return {
                name: browser,
                value: statsSummary[props.category][browser],
                percentage: percentage
            }
        });

        newData.sort((a, b) => b.value - a.value);
        return newData;
    }, [statsSummary, props.category]);

    return <div className='d-flex flex-column border p-5'>
        <p className='p-ui-semibold'>{props.title}</p>

        <PieChart width={400} height={400}>
            <Tooltip content={<CustomTooltip />} />
            <Pie data={data} dataKey="value" nameKey="name" innerRadius={80} outerRadius={130} label={item => item.name} >
                {data.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS_SHADES[index % COLORS_SHADES.length]} />
                ))}
            </Pie>
        </PieChart>
    </div>
}

export default PerCategoryChart;


