import React, { useEffect, useState } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap';
import QRCode from 'qrcode';
import { Shortlink } from '../models/shared/Shortlink';
import { HexColorPicker } from 'react-colorful';

interface QRCodeModalProps {
  onHide: () => void;
  shortlink: Shortlink;
  shortUrl: string;
}

const isValidHexColor = (color: string) => color.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

const QRCodeModal: React.FC<QRCodeModalProps> = (props) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [color, setColor] = useState('#000');

  useEffect(() => {
    if (!canvasRef.current || !isValidHexColor(color)) {
      return;
    }

    QRCode.toCanvas(canvasRef.current, props.shortUrl, {
      errorCorrectionLevel: 'H',
      width: 200,
      color: {
        dark: color,
        light: '#FFF'
      }
    });
  }, [props.shortUrl, color])

  const downloadQR = async () => {
    if (!canvasRef.current) {
      return;
    }

    try {
      const pngUrl = canvasRef.current.toDataURL("image/png").replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "ShortlinkQR.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal show={true} onHide={props.onHide} centered >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.shortlink.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <h4>Customize your QR code and download</h4>
        <label>Customize</label>
        <div className='wrapper d-flex'>
          <div className='center flex-column flex-1'>
            <HexColorPicker color={color} onChange={setColor} />
            <FormControl className="mt-2" style={{ width: '150px' }} type='text' onChange={e => setColor(e.target.value)} value={color} isInvalid={!isValidHexColor(color)} />
          </div>
          <div className='flex-1 center flex-column'>
            <canvas className='m-1' ref={canvasRef} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end'>
          <Button variant='outline-primary' onClick={props.onHide} className='me-2'>
            Cancel
          </Button>
          <Button variant="primary" onClick={downloadQR}>
            Download
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export { QRCodeModal };