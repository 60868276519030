import React from 'react'
import { useSpring, animated } from 'react-spring';
import { formatNumber } from '../utils/shared/format';

interface Props {
    label: string
    valueNumber: number
    className?: string
}

function CountUpNumber(props: Props) {
    const propsSpring = useSpring({
        number: props.valueNumber || 0,
        from: { number: 0 },
        config: { duration: 300 }
    });

    return (
        <div className={`${props.className}`} style={{ width: 220 }}>

            <div className='border rounded p-5 center flex-column'>
                <p className='p-ui-semibold'>{props.label}</p>
                <div className='center' >
                    <h3><animated.span>{propsSpring.number.to(formatNumber)}</animated.span></h3>
                </div>
            </div>
        </div>
    )
}

export default CountUpNumber
