import React from 'react'
import { ButtonProps, Spinner } from 'react-bootstrap'



type Props = ButtonProps & {
    processing: boolean
    children: React.ReactNode
}

export default function ProcessingButton({ processing, disabled, children, ...rest }: Props) {
    return <button {...rest} disabled={processing ||  disabled}>
        {processing && <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className='me-1'
        />} {children}
    </button>
}
