import { FormControl } from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { validateUrl } from '../utils/logic';
import { Shortlink } from '../models/shared/Shortlink';
import React from 'react';
import { updateShortlink } from '../utils/api';
import { Collections, ERROR_KNOWN_THREAD, KNOWN_THREAD_WARNING } from '../utils/shared/constants';
import { Box, CustomModalLayout, FormField, Loader, Modal } from '@wix/design-system';
import { useFirestore } from 'reactfire';
import { collection, getDocs, query, where } from 'firebase/firestore';

interface Inputs {
    alias: string;
    name: string
    targetUrl: string;
}

interface UpdateShortlinkProps {
    onHide: () => void;
    shortlink: Shortlink;
}

export const UpdateShortlinkModal = ({ onHide, shortlink }: UpdateShortlinkProps) => {
    const [processing, setProcessing] = React.useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
        defaultValues: {
            alias: shortlink.alias || shortlink.id,
            targetUrl: shortlink.targetUrl,
            name: shortlink.name
        }
    });
    const firestore = useFirestore();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setProcessing(true);
        try {

            await updateShortlink(shortlink.id, data);
        } catch (e) {
            const message = (e as any).message || 'An error occurred';
            if (message.includes(ERROR_KNOWN_THREAD)) {
                alert(KNOWN_THREAD_WARNING);
            } else {
                console.log('error', e)
            }
        }

        setProcessing(false);
        onHide();
    };

    const isAliasValid = async (value: string) => {
        if (value === shortlink.id) {
            return true;
        }
        if (value === shortlink.alias) {
            return true;
        }

        const q = query(collection(firestore, Collections.shortlinks), where('alias', '==', value));
        const snap = await getDocs(q);

        if (snap.size === 0) {
            return true;
        }

        return 'taken';
    }

    const aliasTaken = errors.alias?.message === 'taken';

    return (
        <Modal onRequestClose={onHide} isOpen={true}>
            <CustomModalLayout
                primaryButtonText="Save"
                primaryButtonProps={{ prefixIcon: processing ? <Loader size="tiny" /> : undefined }}
                secondaryButtonText="Cancel"
                onCloseButtonClick={onHide}
                title="Update Shortlink"
                subtitle="Update existing shortlink details"
                secondaryButtonOnClick={onHide}
                primaryButtonOnClick={handleSubmit(onSubmit)}
                content={
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box direction="vertical" gap={'24px'}>
                            <FormField label="Name" >
                                <FormControl
                                    isInvalid={!!errors.name}
                                    type='text'
                                    {...register('name', { required: true })}
                                />
                            </FormField>

                            <FormField label="Alias"
                                infoContent={"Alias can be used in your short URL to have more human readable links"}
                                statusMessage={aliasTaken ? 'Alias is already taken' : undefined}
                                status={aliasTaken ? 'error' : undefined}
                            >
                                <FormControl
                                    isInvalid={!!errors.alias}
                                    type='text'
                                    {...register('alias', { required: true, validate: isAliasValid })}
                                />
                            </FormField>

                            <FormField label="Target URL" >
                                <FormControl
                                    isInvalid={!!errors.targetUrl}
                                    type='text'
                                    {...register('targetUrl', { validate: validateUrl })}
                                />
                            </FormField>
                        </Box>
                        <button type="submit" style={{ display: 'none' }}>Submit</button>
                    </form>
                }
            />
        </Modal>
        //  title="Update Shortlink" primaryButtonText="Save" secondaryButtonText="Cancel" onPrimaryButtonClick={handleSubmit(onSubmit)} onSecondaryButtonClick={onHide}>
        // <Modal show={true} onHide={onHide} centered>
        //     <Modal.Header closeButton>
        //         <Modal.Title>Update Shortlink</Modal.Title>
        //     </Modal.Header>
        //     <Form onSubmit={handleSubmit(onSubmit)}>
        //         <Modal.Body>
        //             <h4>Update existing shortlink details</h4>
        //             <Form.Group className="mb-3">
        //                 <Form.Label>Name</Form.Label>
        //                 <div className='input-wrapper'>

        //                     <FormControl
        //                         isInvalid={!!errors.name}
        //                         type='text'
        //                         {...register('name', { required: true })}
        //                     />
        //                 </div>
        //             </Form.Group>
        //             <Form.Group className="mb-3">
        //                 <Form.Label>Alias</Form.Label>
        //                 <div className='input-wrapper'>

        //                     <FormControl
        //                         isInvalid={!!errors.alias}
        //                         type='text'
        //                         {...register('alias', { required: true })}
        //                     />
        //                 </div>
        //             </Form.Group>
        //             <Form.Group className="mb-3">
        //                 <Form.Label>Target URL</Form.Label>
        //                 <div className='input-wrapper'>
        //                     <FormControl
        //                         isInvalid={!!errors.targetUrl}
        //                         type='text'
        //                         {...register('targetUrl', { validate: validateUrl })}
        //                     />
        //                 </div>
        //             </Form.Group>
        //         </Modal.Body>
        //         <Modal.Footer>
        //             <div className='d-flex justify-content-end'>
        //                 <Button variant='outline-primary' onClick={onHide} disabled={processing} className='me-2'>
        //                     Cancel
        //                 </Button>
        //                 <ProcessingButton type='submit' className='btn btn-primary' processing={processing}>
        //                     Save
        //                 </ProcessingButton>
        //             </div>
        //         </Modal.Footer>
        //     </Form>
        // </Modal>
    );

};
