import React from 'react'
import { Shortlink } from '../models/shared/Shortlink'
import { Changes } from '../models/FirestoreChanges';
import { useInstanceContext } from '../context/InstanceContext';
import { UpdateShortlinkModal } from './UpdateShortlinkModal';
import { DeleteShortlinkModal } from './DeleteShortlinkModal';
import { QRCodeModal } from './QRCodeModal';
import { Button, EmptyState, IconButton, Table, TableActionCell, Text, TextButton } from '@wix/design-system';
import { Edit, EmptyTrash, ExternalLink, QR, Statistics } from '@wix/wix-ui-icons-common';
import { generateShortUrl } from '../utils/logic';

interface Props {
    showShortLinkForm: () => void
    onShowStats: (id: string) => void
    shortlinks: Shortlink[]
    changes: Changes
}

type OperationType = 'update' | 'delete' | 'qrCode';

interface Operation {
    id: string,
    operation: OperationType
}


export default function ShortlinkList(props: Props) {
    const [currentOperation, setCurrentOperation] = React.useState<Operation | null>(null);
    const { domain } = useInstanceContext();

    if (props.shortlinks.length === 0) {
        return <EmptyState
            title="No QR Codes Yet"
            subtitle={<Text><TextButton onClick={props.showShortLinkForm}>Add a new QR code</TextButton> to get started</Text>}
            image={<img src="so-really.gif" alt='No shortlinks' />}
        />
    }


    const renderOperation = (shortlink: Shortlink) => {
        if (!currentOperation || currentOperation.id !== shortlink.id) {
            return null;
        }

        switch (currentOperation.operation) {
            case 'update':
                return <UpdateShortlinkModal
                    onHide={() => setCurrentOperation(null)}
                    shortlink={shortlink}
                />
            case 'delete':
                return <DeleteShortlinkModal
                    onHide={() => setCurrentOperation(null)}
                    shortlink={shortlink}
                />
            case 'qrCode':
                return <QRCodeModal
                    onHide={() => setCurrentOperation(null)}
                    shortlink={shortlink}
                    shortUrl={generateShortUrl(shortlink, domain, { forceId: true })}
                />
            default:
                return null;
        }
    };


    return (
        <Table
            data={props.shortlinks}
            columns={[
                {
                    title: 'Name',
                    render: shortlink => shortlink.name,
                },
                {
                    title: 'Short URL',
                    render: shortlink => generateShortUrl(shortlink, domain),
                },
                {
                    title: "QR Code",
                    width: '100px',
                    align: 'center',
                    render: shortlink => <IconButton
                        onClick={() => setCurrentOperation({ id: shortlink.id, operation: 'qrCode' })}
                        priority="secondary"
                    >
                        <QR />
                    </IconButton>
                },
                {
                    title: 'Target URL',
                    render: shortlink => {
                        const longUrl = shortlink.targetUrl.replace(/^https?:\/\//, '').slice(0, 10) + '...';
                        return <a href={shortlink.targetUrl} target='_blank' rel="noreferrer">{longUrl} <ExternalLink /></a>
                    }
                },
                {
                    title: 'Statistics',
                    width: '100px',
                    render: shortlink => <Button suffixIcon={<Statistics />} onClick={() => props.onShowStats(shortlink.id)}>{shortlink.visited}</Button>
                },
                {
                    title: '',
                    width: '80px',
                    render: shortlink => <>
                        <TableActionCell
                            secondaryActions={[
                                {
                                    text: 'Update',
                                    icon: <Edit />,
                                    onClick: () => setCurrentOperation({ id: shortlink.id, operation: 'update' }),
                                },
                                {
                                    divider: true,
                                },
                                {
                                    text: 'Delete',
                                    icon: <EmptyTrash />,
                                    skin: 'destructive',
                                    onClick: () => setCurrentOperation({ id: shortlink.id, operation: 'delete' }),
                                }
                            ]}
                        />

                        {renderOperation(shortlink)}
                    </>
                }

            ]}
        />
    );
}