import { useState } from "react";

function NotFound() {
    const [showImg, setShowImg] = useState(false);

    return <div className="not-found center">
        <div className="background-container">

            <img onLoad={() => setShowImg(true)} alt="" src="sky.jpg" className="rotating-image" style={{ opacity: showImg ? 1 : 0 }} />
        </div>
        <div className="main">
            <h1>404</h1>
            <h2>Link not found</h2>

            <p>“Shoot for the moon. Even if you miss, you'll land among the stars.”</p>

        </div>
    </div>;
}

export default NotFound;