export const getStatDayKey = (date: Date): string => {
    return date.toISOString().split('T')[0];
}

export const formatNumber = (input: number) => {
    const rounded = Math.round(input);
    return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDate = (input: Date | string, options: DateFormatOptions = {}) => {
    const date = new Date(input);

    if (options.day === 'short') {
        // Return 3 letter day of week
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[date.getDay()];
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${zeroPad(day)}. ${zeroPad(month)}. ${year}`;
}

interface DateFormatOptions {
    day?: 'short'
}

export const formatDateTime = (input: Date | string, options: DateFormatOptions = {}) => {
    const date = new Date(input);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    let day = formatDate(date, options);
    if (options.day === 'short') {
        day += ',';
    }

    return `${day} ${zeroPad(hours)}:${zeroPad(minutes)}`;
}


export const fromUtcTimeToLocalTime = (timeUTC: string) => {
    const date = new Date();
    const [hours, minutes] = timeUTC.split(':');
    date.setUTCHours(parseInt(hours));
    date.setUTCMinutes(parseInt(minutes));
    return `${zeroPad(date.getHours())}:${zeroPad(date.getMinutes())}`;
}

export const fromLocalTimeToUtcTime = (timeLocal: string) => {
    const date = new Date();
    const [hours, minutes] = timeLocal.split(':');
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));
    return `${zeroPad(date.getUTCHours())}:${zeroPad(date.getUTCMinutes())}`;
}
export const zeroPad = (input: number | string) => {
    const inputString = input + '';
    return inputString.length === 1 ? `0${inputString}` : inputString;
};

